.data-lembaga-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.data-lembaga-container h3{
    font-size: 18px;
}

.data-lembaga-container span{
    font-size: 12px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.bg-image{
    width: 100%;
    box-shadow: 0px 0px 10px gray;
    margin-bottom: 20px;
}

.bg-image img{
    width: 100%;
    display: block;
    border-radius: 5px;
}

.deskripsi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.deskripsi .detail{
    width: 100%;
}

.lembaga-title{
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin: 15px 0px;
}

.lembaga-gambar{
    width: 250px;
    box-shadow: 0px 0px 10px gray;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
}

.lembaga-gambar img{
    border-radius: 5px;
    width: 100%;
    height: 200px;
    display: block;
}

.desc{
    font-size: 12px;
    margin: 5px 0px;
}

.icon-bumdes{
    width: 100px;
    margin: 10px auto;
    cursor: pointer;
    display: block;
}