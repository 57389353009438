.copyright-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgb(37, 150, 190);
    color: white;
    border-top: 4px solid rgb(80, 191, 231);
    margin-top: 20px;
}

.copyright-text{
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
}

.supported{
    font-size: 12px;
}

.supported a:hover{
    color: rgb(80, 191, 231);
}

.support{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}

.support img{
    height: 25px;
    display: block;
    margin: 0px 10px;
    margin-top: 20px;
}