body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  background-image: url(./assets/picture/bg-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  background-attachment: fixed;
  min-height: 100%;
}

.App {
  width: 100%;
  background-color: white;
  max-width: 577px;
  min-height: 100vh;
  margin: 0px auto;
  position: relative;
}

@media all {
  .page-break {
    display: none;
  }
}

/* @media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
} */