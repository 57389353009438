.keperluan{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.keperluan label{
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}

.keperluan input{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 80%;
    color: black;
}

.cap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 20px 30px;
}

.cap .menyatakan, .cap .ttd{
    display: block;
    text-align: left;
}

.menyatakan span:first-child{
    display: block;
    font-size: 12px;
    margin-bottom: 115px;
}

.menyatakan span:last-child{
    display: block;
    font-size: 12px;
}

.cap .ttd{
    margin: 0;
    padding: 0;
}