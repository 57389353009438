.saran-container{
    width: 90%;
    margin: 0px auto 30px;
}

.saran-container h3{
    font-size: 18px;
    text-align: center;
}

.saran-container span{
    font-size: 12px;
    display: block;
}

.form-saran{
    margin: 10px 0px 15px;
    font-size: 12px;
}

.form-saran label{
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
}

.form-saran input, textarea{
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px;
    width: 80%;
    font-family: 'poppin', sans-serif;
}

textarea{
    height: 200px;
}

.btn-small{
    width: 50px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: rgb(37, 150, 190);
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.btn-small:hover{
    background-color: rgb(80, 191, 231);
}