.menu-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 20px auto 20px;
}

.menu-container h3{
    font-size: 18px;
}

.menu-icon{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

a{
    text-decoration: none;
}

.icon{
    flex: 1;
    text-align: center;
}

.icon img{
    width: 50px;
    height: 40px;
}

.icon span{
    font-size: 11px;
    text-align: center;
    display: block;
    color: black;
}