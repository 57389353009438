.banner,
.banner-bottom{
    width: 90%;
    margin: 0px auto 20px;
}

.banner img,
.banner-bottom img{
    display: block;
    box-shadow: 0px 0px 10px gray;
    width: 100%;
}