.sidebar-container{
    display: block;
    padding: 20px;
    background-color: rgb(37, 150, 190);
    color: white;
    position: fixed;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 3;
    transition: ease-in 0.4s;
}

.menu-title{
    font-size: 14px;
    font-weight: 700;
    margin-top: 30px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 3px solid white;
}

.menu-group{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 280px;
    box-sizing: border-box;
}

.menu-group a, .menu-logout{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    margin: 5px 0px;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease;
}

.menu-group img, .menu-logout img{
    width: 18px;
    display: block;
    margin-right: 10px;
}

.menu-admin{
    font-size: 12px;
    width: 100%;
}

.menu-group a:hover, .active, .menu-logout:hover{
    background-color: rgb(44, 163, 206);
}

.menu-group a:hover, .active{
    border-left: 3px solid white;
}

.close-menu{
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
}

.close-menu img{
    width: 18px;
}