.infografis-container{
    width: 90%;
    margin: 0px auto 30px;
}

.infografis-container h3{
    font-size: 18px;
    text-align: center;
}

.infografis-container span{
    font-size: 12px;
}

.loading-text{
    margin-top: 10px;
    margin-bottom: 10px;
}

.infografis-container .info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.info .info-data{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 42%;
    min-width: 150px;
    height: 100px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(37, 150, 190);
    color: white;
    margin: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.info .info-data .item-info{
    font-size: 12px;
}

.info .info-data span{
    display: block;
}

.info .info-data .info-desc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.info .info-data .info-desc span{
    font-size: 14px;
    font-weight: 600;
}

.info .info-data .info-desc .fa{
    font-size: 30px;
    text-align: right;
    color: rgb(80, 191, 231);
}

.update{
    display: block;
    margin-top: 15px;
}