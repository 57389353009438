.berita-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto 30px;
    width: 90%;
}

.berita-container h3{
    font-size: 18px;
}

.berita{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 10px;
}

.berita-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 160px;
    margin-right: 10px;
    padding-right: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.berita-image{
    width: 160px;
    overflow: hidden;
    margin-bottom: 10px;
}

.berita-image img{
    display: block;
    width: 100%;
    height: 100px;
    border-radius: 8px;
}

.judul-berita span{
    font-size: 11px;
    color: black;
    display: block;
}