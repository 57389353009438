.data-domisili-container{
    width: 90%;
    margin: 0px auto 20px;
}

.data-domisili-container h3{
    font-size: 18px;
    text-align: center;
}

.data-domisili-container span{
    font-size: 12px;
    display: block;
}

.form-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px 15px;
}

.form-content label{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
}

.form-content input{
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid grey;
}

.form-content input[type="text"]:disabled {
    background: #dddddd;
}