.data-surat-container{
    width: 90%;
    margin: 0px auto 20px;
    font-size: 12px;
}

.a{
    text-decoration: none;
}

.to-dashboard{
    color: white;
    font-size: 12px;
    background-color: blue;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
}

.to-dashboard .fa{
    font-size: 16px;
}

.to-dashboard:hover{
    background-color: rgb(19, 19, 236);
}

.data-surat-container h3{
    font-size: 18px;
    text-align: center;
}

.data-surat-container span{
    font-size: 12px;
    display: block;
}

.jenis-surat{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
}

.jenis-surat a{
    text-decoration: none;
    color: black;
    display: block;
}

.item-surat{
    padding: 10px;
    margin: 5px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;
    box-sizing: border-box;
}

.item-surat span:first-child{
    font-size: 12px;
}

.create{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 8px;
    font-size: 12px;
    text-align: center;
    background-color: green;
    border-radius: 5px;
    width: 80px;
    cursor: pointer;
}

.create:hover{
    background-color: rgb(21, 194, 21);
}

.create .fa{
    font-size: 14px;
}

.create span{
    font-size: 12px;
    display: block;
}