.rkp-deskripsi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.rkp-deskripsi h3{
    font-size: 18px;
    text-align: center;
}

.rkp-deskripsi span{
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
}

.bg-image{
    width: 100%;
    box-shadow: 0px 0px 10px gray;
    margin-bottom: 20px;
}

.bg-image img{
    width: 100%;
    display: block;
    border-radius: 5px;
}

.rkp-image{
    width: 100%;
    text-align: center;
}

.rkp-image img{
    width: 100px;
}

.download-bottom{
    width: 200px;
    padding: 5px 0;
    font-size: 12px;
    background-color: green;
    color: ivory;
    cursor: pointer;
    border-radius: 5px;
    margin: 0px auto;
    margin-top: 10px;
}

.download-bottom:hover{
    background-color: rgb(6, 172, 6);
}