.profil-deskripsi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.profil-deskripsi h3 {
    font-size: 18px;
}

.bg-image {
    width: 100%;
    box-shadow: 0px 0px 10px gray;
    margin-bottom: 20px;
}

.bg-image img {
    width: 100%;
    display: block;
    border-radius: 5px;
}

.deskripsi {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.sub-header {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
    text-align: left;
    width: 100%;
}

.content-profile {
    font-size: 12px;
    margin: 5px;
}

/* .gambar-perangkat{
    width: 90%;
}

.gambar-perangkat img{
    width: 100%;
    display: block;
} */

.content-profile ul li {
    font-size: 12px;
}

.profil-kepala-desa {
    text-align: center;
    margin: 10px auto;
}

.profil-kepala-desa img {
    width: 200px;
    box-shadow: 0px 0px 5px lightskyblue;
    border-radius: 4px;
    display: block;
    margin-bottom: 5px;
}

.nama-kepala-desa {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.jabatan-kepala-desa {
    font-weight: 600;
    font-size: 12px;
}

.detail-perangkat {
    text-align: left;
    width: 100%;
}

.styled-table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
    box-sizing: border-box;
}

.styled-table thead tr {
    background-color: rgb(37, 150, 190);
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.styled-table th,
.styled-table td {
    padding: 8px 5px;
    box-sizing: border-box;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
    text-align: center;
    font-size: 11px;
    width: fit-content;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
    font-size: 11px;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid rgb(37, 150, 190);
}

.styled-table tbody tr.active-row {
    color: rgb(37, 150, 190);
}

.deskripsi span,
.tabel-perangkat {
    display: block;
    font-size: 12px;
    width: 100%;
}

.deskripsi p {
    font-size: 12px;
}

.foto-perangkat {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.foto-perangkat img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.deskripsi .operasional {
    font-size: 12px;
    width: 100%;
    text-align: left;
}

.deskripsi .operasional span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

/* .deskripsi .operasional span:last-child {
    color: red;
} */

.deskripsi .fa {
    font-size: 16px;
    margin-right: 10px;
    color: green;
}

.deskripsi .fa-times-circle {
    color: red;
}