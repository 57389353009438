.pembayaran-container{
    width: 90%;
    margin: 0px auto 20px;
}

.a{
    text-decoration: none;
}

.to-dashboard{
    color: white;
    font-size: 12px;
    background-color: blue;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
}

.to-dashboard .fa{
    font-size: 16px;
}

.to-dashboard:hover{
    background-color: rgb(19, 19, 236);
}

.pembayaran-container h3{
    font-size: 18px;
    text-align: center;
}

.pasar-desaku-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.filter-produk{
    flex: 6;
}

.filter-produk span{
    font-size: 12px;
    display: block;
}

.filter-produk .input-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 80%;
}

.input-filter input{
    border: 1px solid rgb(37, 150, 190);
    border-radius: 5px;
    padding: 8px;
    margin-right: 10px;
    width: 80%;
}

.display-produk{
    width: 100%;
}

.produk-container{
    width: 100%;
}

.produk-container .sortir-produk{
    margin-bottom: 10px;
    font-size: 12px;
}

.produk-container span{
    font-size: 12px;
}

.produk-container .sortir-produk .kategori-produk{
    display: block;
}

.kategori-produk .kategori{
    display: inline-block;
    margin: 5px 5px 0px 0px;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(80, 191, 231);
    color: white;
    font-size: 10px;
    cursor: pointer;
}

.kategori-produk .kategori:hover,
.kategori-produk .kategori.aktif{
    background-color: rgb(37, 150, 190) !important;
}

.produk-card-container{
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
}

.produk-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 45%;
    padding: 5px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 10px auto;
    box-sizing: border-box;
}

.list-produk-container img{
    width: 100%;
    display: block;
    border-radius: 5px;
    margin-bottom: 8px;
}

.list-produk-container .rates{
    color: yellow;
    margin-bottom: 10px;
}

.list-produk-container .rates .fa{
    margin-right: 2px;
    font-size: 12px;
}

.list-produk-container .fa-tags, .list-produk-container .fa-shopping-cart{
    margin-right: 5px;
    font-size: 12px;
}

.list-produk-container .nama-produk{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.list-produk-container .harga-produk,
.list-produk-container .stok-produk{
    font-size: 12px;
    margin-bottom: 4px;
    display: block;
}

.tambah-pesanan{
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(37, 150, 190);
    color: white;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    box-sizing: border-box;
    margin-top: 10px;
}

.tambah-pesanan:hover{
    background-color: rgb(80, 191, 231);
}

.tambah-pesanan .fa{
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
}

.tambah-pesanan span{
    display: inline-block;
    font-size: 10px;
}