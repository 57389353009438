.media-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.media-container h3{
    font-size: 18px;
}

.media-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon{
    margin: 0px 10px;
    font-size: 24px;
    color: rgb(37, 150, 190);
    cursor: pointer;
}

.icon a{
    font-size: 24px;
    color: rgb(37, 150, 190);
    cursor: pointer;
}

.icon:hover{
    color: rgb(80, 191, 231);
}