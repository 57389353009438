.data-aset-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.data-aset-container h3{
    font-size: 18px;
}

.data-aset-container span{
    font-size: 12px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.bg-image{
    width: 100%;
    box-shadow: 0px 0px 10px gray;
    margin-bottom: 20px;
}

.bg-image img{
    width: 100%;
    display: block;
    border-radius: 5px;
}

.deskripsi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.aset-title{
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin: 10px 0px;
}

.aset-gambar{
    width: 60%;
    box-shadow: 0px 0px 10px gray;
    border-radius: 5px;
    text-align: center;
}

.aset-gambar img{
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
    display: block;
    margin-bottom: 10px;
}

.desc{
    font-size: 12px;
}

.icon-bumdes{
    width: 100px;
    margin: 10px auto;
    cursor: pointer;
    display: block;
}