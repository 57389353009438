.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgb(37, 150, 190);
    color: white;
    margin-bottom: 20px;
    position: relative;
    height: 40px;
}

a{
    text-decoration: none;
    color: white;
}

.logo{
    cursor: pointer;
}

.logo img{
    width: 150px;
}

.page{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2em;
}

.personalisasi{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
}

.personalisasi span{
    font-size: 12px;
    margin-right: 10px;
}

.personalisasi .fa,
.logo .fa{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: block;
}

.user-hide{
    display: none;
}

.user-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10px;
    width: 100px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: rgb(37, 150, 190);
    border-radius: 5px;
    z-index: 5;
}

.user-info span{
    font-size: 10px;
    margin: 5px 0px;
    cursor: pointer;
    display: block;
}

.user-info span:hover{
    color: rgb(247, 238, 238);
}

.menu-bar img{
    width: 20px;
    cursor: pointer;
}