.keperluan{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
}

.keperluan label{
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}

.keperluan input{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 80%;
    color: black;
}