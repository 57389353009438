.data-admin-deskripsi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    margin: 0px auto 30px;
}

.data-admin-deskripsi h3{
    font-size: 18px;
    text-transform: uppercase;
    margin: 0px auto 20px;
}

.data-admin-deskripsi span{
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
}

.tambah-btn{
    background-color: green;
    color: white;
    padding: 8px;
    border-radius: 5px;
    font-size: 12px;
    width: fit-content;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.tambah-btn span{
    color: ivory;
    display: block;
}

.alert-info{
    font-size: 10px;
    color: red;
    margin-top: -5px;
    margin-bottom: 5px;
}

.tambah-btn:hover{
    background-color: rgb(11, 197, 11);
}

.action{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#icon-tambah {
    color: ivory;
    font-size: 16px;
    display: block;
}

#icon-remove{
    color: red;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin-left: 5px;
}

#icon-remove:hover{
    color: rgb(233, 17, 17);
}

#icon-edit{
    color: green;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin-right: 5px;
}

#icon-edit:hover{
    color: rgb(8, 187, 8);
}

.tambah-btn .btn-text{
    color: ivory;
    font-size: 12px;
}

.deskripsi{
    width: 100%;
    box-sizing: border-box;
}

.deskripsi-title{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    padding: 5px 8px;
    box-sizing: border-box;
    background-color: rgb(37, 150, 190);
    color: ivory;
}

.deskripsi-subtitle{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    border-bottom: solid 3px rgb(37, 150, 190);
    padding-bottom: 5px;
    width: 100%;
    box-sizing: border-box;
}

.form-content-admin{
    width: 100%;
}

.form-content-admin label{
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
}

.deskripsi .form-content-admin input,
.deskripsi .data-content input{
    border: solid 1px gray;
    outline: none;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.detail-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
    margin-bottom: 15px;
}

.data-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    margin-top: 5px;
}

.data-content input{
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

/* style monografi */
.monografi-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.form-monografi{
    display: flex;
    width: 45%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 5px;
    box-shadow: 4px 4px lightgray;
    box-sizing: border-box;
    border: 1px solid lightgrey;
    border-radius: 4px;
}

.info-monografi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.monografi-title{
    font-size: 11px;
    margin-bottom: 10px;
}

.info-monografi input{
    padding: 20px 10px;
    width: 100px;
    height: 30px;
    margin-bottom: 10px;
    outline: none;
    border: 1px dashed rgb(80, 191, 231);
    border-radius: 4px;
    box-sizing: border-box;
}

.btn-update{
    background-color: rgb(37, 150, 190);
    padding: 5px;
    border-radius: 4px;
    color: ivory;
    font-size: 10px;
    cursor: pointer;
    margin-top: 5px;
}

.pop-up-ubah-data .ubah-data-container{
    width: 80%;
    max-width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    background-color: rgb(37, 150, 190);
    padding: 30px 20px;
    border-radius: 10px;
    z-index: 11;
}

.pop-up-ubah-data .ubah-data-container .ubah-data-subtitle {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
    color: ivory;
    margin-bottom: 15px;
    text-align: center;
}

.pop-up-data-content{
    margin-top: 10px;
}

.pop-up-data-content .item-data{
    color: ivory;
}

.pop-up-data-content input{
    outline: transparent;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 80%;
    font-size: 12px;
}

#close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: ivory;
}

#close-btn:hover{
    color: red;
}

.btn-text{
    background-color: green;
    padding: 5px 20px;
    border-radius: 5px;
    color: ivory;
    font-size: 14px;
    width: fit-content;
    margin-top: 20px;
    cursor: pointer;
}

.overlay{
    background-color: lightgray;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    opacity: 0.6;
}

.unggah-container{
    margin-top: 10px;
    width: 100%;
}

.unggah-container input{
    margin-bottom: 10px;
}

.unggah-container .format-unggah{
    font-size: 9px;
    color: grey;
    margin-bottom: 10px;
}

.unggah-container .btn-submit input{
    width: fit-content;
    text-align: center;
    padding: 8px 10px;
    border-radius: 5px;
    border: none;
    background-color: rgb(37, 150, 190);
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.unggah-container .sukses{
    color: green;
    font-size: 9px;
}

.unggah-container .gagal{
    color: red;
    font-size: 9px;
}

/* Arsip Surat */
.arsip-table{
    border-collapse: collapse;
	width: 100%;
    box-sizing: border-box;
    text-align: center;
    overflow-x: scroll;
}

.arsip-table th,
.arsip-table td {
    border: 1px solid #e1edff;
	box-sizing: border-box;
}

.arsip-table thead th{
    background-color: #508abb;
	color: #FFFFFF;
	border-color: #6ea1cc !important;
	text-transform: uppercase;
    font-size: 12px;
    padding: 8px 5px;
    box-sizing: border-box;
}

.arsip-table tbody td{
    color: #353535;
    font-size: 11px;
    padding: 5px 4px;
    box-sizing: border-box;
}

.arsip-table tbody tr:nth-child(odd) td {
	background-color: #f4fbff;
}

.arsip-table tbody td .col-nama,
.arsip-table tbody td .col-nik,
.arsip-table tbody td .col-nomor,
.arsip-table tbody td .col-jenis{
    display: flex;
    flex: 3;
}

.arsip-table tbody td .col-aksi{
    display: flex;
    flex: 2;
}

/* .arsip-table tbody tr:hover td {
	background-color: #ffffa2;
	border-color: #f5f563;
	transition: all .1s;
    cursor: pointer;
} */

.info-container input,
.info-container textarea{
    border: solid 1px gray;
    outline: none;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

/* style detail data warga */
.detail-warga-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.detail-warga-container h3{
    text-transform: uppercase;
}

.data-warga{
    width: 100%;
    font-size: 12px;
    border-top: 3px solid rgb(37, 150, 190);
    padding-top: 10px;
}

.item-data{
    display: block;
    margin-bottom: 10px;
}

.item-data span{
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.item-data input[type="text"], .item-data input[type="date"], .item-data textarea, .item-data input[type="number"]{
    border: solid 1px gray;
    outline: none;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.item-data input[type="file"]{
    border: none;
    outline: none;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.tambah-btn{
    margin-top: 20px;
}

.update-data-warga-btn{
    padding: 8px 10px;
    border-radius: 5px;
    background-color: green;
    color: ivory;
    margin-top: 20px;
    margin-bottom: 10px;
    width: fit-content;
    cursor: pointer;
}

.update-data-warga-btn:hover{
    background-color: rgb(7, 153, 7);
}

/* halaman tambah warga */
.tambah-warga-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0px auto 20px;
}

.tool-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0px 15px;
}

.deskripsi-tambah-warga{
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
}

.data-admin-deskripsi .tambah-warga-icon{
    display: block;
    width: fit-content;
    padding: 3px 8px;
    background-color: green;
    color: ivory;
    border-radius: 5px;
}

.data-admin-deskripsi .tambah-warga-icon:hover{
    background-color: rgb(5, 150, 5);
}

.data-admin-deskripsi .tambah-warga-icon a{
    color: ivory;
    font-size: 11px;
}

#tambah-warga{
    margin-right: 5px;
    font-size: 14px;
}

#cari-warga{
    margin-right: 5px;
    color:rgb(37, 150, 190);
}

.filter-warga{
    border: 1px solid rgb(37, 150, 190);
    border-radius: 8px;
    padding: 2px 5px;
    width: fit-content;
}

.filter-warga input{
    border: none;
    outline: none;
    color: rgb(37, 150, 190);
}

.filter-warga input::placeholder{
    color: rgb(37, 150, 190);
    font-size: 12px;
}

.konfirmasi-delete{
    color: black;
}

.loading-msg{
    font-size: 12px;
    text-align: center;
    margin: 10px auto;
}

/* admin berita */
.menu-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tambah-berita-icon{
    padding: 5px;
    background-color: green;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    width: 80px;
    text-align: center;
}

.tambah-berita-icon:hover{
    opacity: 0.9;
}

.list-berita-subtitle{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: rgb(37, 150, 190);
}

.list-berita-container{
    position: relative;
    width: 100%;
    padding: 10px;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 5px lightgray;
    border-radius: 4px;
    margin: 10px auto;
    box-sizing: border-box;
}

.list-berita-container img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
}

.list-title{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}

.list-caption{
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.list-desc{
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.list-desc .author-list{
    color: rgb(37, 150, 190);
    display: block;
    margin-right: 5px;
}

.list-desc .date-list{
    display: block;
}

.image-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px lightskyblue;
    margin-bottom: 20px;
}

.image-list img{
    width: 100%;
}

.hapus-berita, .ubah-berita{
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 12px;
    cursor: pointer;
    color: ivory;
}

#remove-list-berita{
    font-size: 16px;
    margin-right: 5px;
}

.hapus-berita{
    background-color: red;
    margin-right: 10px;
}

.ubah-berita{
    background-color: orange;
}

.action-btn-list:hover{
    opacity: 0.9;
}

/* admin Produk */
.tambah-produk-icon{
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 8px;
    background-color: rgb(37, 150, 190);
    margin-bottom: 5px;
}

.list-produk-subtitle{
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0px;
}

.list-produk-container{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 4px;
    box-shadow: 0px 0px 3px lightgray;
    margin: 5px 0px;
    box-sizing: border-box;
}

.detail-produk-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.image-produk img{
    width: 120px;
    display: block;
}

.image-produk{
    flex: 2;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.deskripsi-produk{
    flex: 3;
}

.nama-produk{
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.harga-produk, .stok-produk, .rate-produk, .phone-produk{
    font-size: 12px;
}

.action-product{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

a .edit-produk, .hapus-produk{
    padding: 5px 8px;
    border-radius: 4px;
    color: ivory;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}

a .edit-produk{
    background-color: green;
    margin-right: 10px;
}

.hapus-produk{
    background-color: red;
}

.edit-produk:hover, .hapus-produk:hover{
    opacity: 0.9;
}

/* pagination */
.pagination-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.pagination-container .info-halaman{
    display: block;
    font-size: 12px;
}

.pagination-container .arrow{
    color: ivory;
    cursor: pointer;
    margin: 0px 10px;
}

.fa-arrow-left,
.fa-arrow-right{
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
    background-color: rgb(37, 150, 190);
}

.fa-arrow-left:hover,
.fa-arrow-right:hover{
    background-color: rgb(80, 191, 231);
}