.slider-container{
    width: 90%;
    display: block;
    margin: 0px auto;
    overflow: hidden;
}

.slider-container .slide{
    box-shadow: 0px 0px 5px aqua;
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.slider-container .slide img{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.slider-container .slide span{
    display: block;
    font-size: 14px;
    font-weight: 600;
}

.slider-container .slide .legend{
    position: absolute;
    bottom: 20px;
    right: 0;
    background-color: transparent;
    text-align: left;
}