.papan-info-container{
    width: 90%;
    margin: 0px auto 30px;
}

.papan-info-container h3{
    font-size: 18px;
    text-align: center;
}

.papan-info-container span{
    font-size: 12px;
    display: inline-block;
}

.papan-info{
    width: 100%;
    margin: 10px auto;
    box-sizing: border-box;
    overflow: hidden;
}

.papan-info img{
    display: block;
    object-fit: cover;
    width: 100%;
    box-shadow: 0px 0px 10px gray;
}