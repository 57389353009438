.option-surat{
    width: 90%;
    margin: 0px auto 20px;
}

.option-surat h3{
    text-align: center;
}

.option-surat .tombol{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.option-surat .tombol .simpan,
.option-surat .tombol .kembali-surat{
    padding: 8px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    margin-right: 5px;
    cursor: pointer;
}

.option-surat .tombol .simpan{
    background-color: green;
}

.option-surat .tombol .simpan:hover{
    background-color: rgb(19, 163, 19);
}

.option-surat .tombol .kembali-surat{
    background-color: blue;
}

.option-surat .tombol .kembali-surat:hover{
    background-color: rgb(31, 31, 235);
}

.form-surat-container{
    width: 90%;
    margin: 0px auto 20px;
    padding: 80px 50px 20px;
    box-sizing: border-box;
}

.header-surat{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo-surat{
    flex: 1;
    text-align: right;
}

.kop-surat{
    flex: 4;
    text-align: center;
}

.kop-surat span:nth-child(1),
.kop-surat span:nth-child(2),
.kop-surat span:nth-child(3){
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.kop-surat span:nth-child(3){
    margin-bottom: 5px;
}

.kop-surat span:nth-child(4),
.kop-surat span:nth-child(5){
    display: block;
    font-size: 11px;
    text-align: center;
}

.logo-surat img{
    width: 100px;
}

hr.garis-1{
    color: black;
    margin-bottom: -7px;
}

hr.garis-2{
    border: 2px solid black;
    background-color: black;
}

.judul-nomor-surat{
    text-align: center;
    margin: 30px auto;
    font-weight: 600;
    font-size: 14px;
}

.judul-nomor-surat span:nth-child(1){
    display: block;
    border-bottom: 3px solid black;
    width: fit-content;
    margin: 20px auto 8px;
}

.judul-nomor-surat span:nth-child(2){
    display: block;
}

.isi-surat{
    margin: 20px 0px;
}

.isi-surat span{
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    text-indent: 20px;
    text-align: justify;
}

.biodata-surat{
    margin-bottom: 10px;
}

.biodata-surat table tr td{
    font-size: 12px;
}

.biodata-surat td{
    vertical-align: top;
}
.biodata-surat tbody tr td:first-child{
    width: 140px;
}

.signature{
    position: absolute;
}

.cap .signature{
    position: relative;
    left: -20px;
    top: -20px;
}

.ttd{
    font-size: 12px;
    width: 210px;
    margin: 50px 0px 10px auto;
    box-sizing: border-box;
}

.ttd span{
    display: block;
}

.ttd span:nth-child(2){
    margin-bottom: 100px;
}

.ttd img{
    height: 150px;
    position: relative;
    z-index: 3;
    top: -20px;
    left: -30px;
    margin-bottom: -40px;
}

/* peringatan filter surat */
.alert-container{
    width: 80%;
    max-width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    background-color: lightgrey;
    padding: 30px 20px;
    border-radius: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.alert-container .fa-times-circle{
    font-size: 100px;
    color: red;
    display: block;
    margin-bottom: 20px;
}

.alert-container span{
    font-size: 12px;
    text-align: center;
    color: black;
    display: block;
}

.alert-container .ok-btn{
    font-size: 12px;
    cursor: pointer;
    color: ivory;
    background-color: blue;
    border-radius: 4px;
    padding: 5px 8px;
    width: 40px;
    text-align: center;
    margin-top: 20px;
}

.alert-container .ok-btn:hover{
    background-color: rgb(13, 13, 233);
}