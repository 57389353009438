.data-apbdesa{
    width: 90%;
    margin: 0px auto 20px;
}

.data-apbdesa h3{
    font-size: 18px;
}

.apbdesa-title{
    text-align: center;
}

.apbdesa-image{
    width: 100%;
    text-align: center;
}

.data-apbdesa img{
    margin-top: 10px;
}

.data-apbdesa span{
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
}

.apbdesa-image img{
    width: 100px;
}

.apbdesa-image .download-bottom{
    text-align: center;
}