.data-berita-container{
    width: 90%;
    margin: 0px auto 30px;
}

.title-berita{
    display: block;
    margin-bottom: 10px;
}

.title-berita .title{
    display: block;
    font-size: 18px;
    color: rgb(37, 150, 190);
    font-weight: 600;
}

.title-berita .title-desc{
    font-size: 10px;
}

.image-berita{
    width: 100%;
    border-radius: 5px;
}

.image-berita img{
    display: block;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 10px gray;
    margin-bottom: 5px;
}

.image-berita span{
    font-size: 9px;
    display: block;
    color: grey;
}

.body-berita{
    margin: 10px 0px;
}

.body-berita .paragraf{
    font-size: 11px;
    margin: 10px 0px;
}

.body-berita .baca-juga{
    font-size: 10px;
}

.body-berita .baca-juga span:first-child{
    color: grey;
    display: block;
}

.body-berita .baca-juga span:last-child{
    color: rgb(37, 150, 190);
    display: block;
    cursor: pointer;
}

.body-berita .baca-juga span:last-child:hover{
    color: rgb(80, 191, 231);
}

.body-berita span{
    display: block;
}

.berita-lain{
    width: 100%;
    margin: 20px 0px 30px;
}

.berita-lain .title-berita-lain{
    font-size: 14px;
    font-weight: 600;
    color: rgb(80, 191, 231);
    display: block;
    margin-bottom: 10px;
}

.detail-berita-lain-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.detail-berita-lain-container:hover{
    box-shadow: 0px 0px 5px gray;
}

.detail-berita-lain-container .deskripsi-judul{
    flex: 1;
}

.deskripsi-judul span:nth-child(1){
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.deskripsi-judul span:nth-child(2){
    font-size: 12px;
    color: rgb(80, 191, 231);
    display: block;
}

.deskripsi-judul span:nth-child(3){
    font-size: 10px;
}

.detail-berita-lain-container .gambar-berita{
    flex: 1;
    text-align: right;
}

.gambar-berita img{
    width: 90%;
    height: 120px;
    box-shadow: 0px 0px 5px gray;
    border-radius: 5px;
}