.login-container{
    width: 80%;
    max-width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    background-color: rgb(37, 150, 190);
    padding: 30px;
    border-radius: 10px;
    z-index: 10;
}

.login-container h3{
    font-size: 14px;
    text-align: center;
}

.login-container .login-subtitle {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
}

.login-container .error-message{
    font-size: 11px;
    display: block;
    margin: 10px 0px;
    color: red;
    text-align: center;
}

.login-subtitle{
    font-size: 12px;
}

.form-group{
    width: 100%;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.login-container .form-group .fa{
    margin-right: 10px;
    color: lightgray;
}

.login-container input{
    width: 80%;
    outline: transparent;
    border: none;
    font-size: 12px;
    background-color: transparent;
}

.login-container input::placeholder{
    color: lightgray;
}

.login-container .btn-login{
    background-color: white;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: black;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.login-container .btn-login:hover{
    opacity: 0.9;
}

.login-container .close-btn{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
    color: red;
}

.login-container .close-btn:hover{
    opacity: 0.9;
}

.overlay{
    background-color: lightskyblue;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    opacity: 0.6;
}